import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { DiscussionEmbed } from 'disqus-react';

import ContentWrapper from '../components/contentWrapper/contentWrapper';
import facebookPath from '../utils/facebookShareBuilder';
import Layout from '../components/layout';
import twitterPath from '../utils/twitterShareBuilder';
import {
  dateBuilder,
  pathBuilder,
  assetPathBuilder,
} from '../utils/pathBuilder';

import { ReactComponent as FacebookIcon } from '../images/svgs/facebook-logo.svg';
import { ReactComponent as TwitterIcon } from '../images/svgs/twitter-logo.svg';
import styles from '../styles/templates/messageTemplate.module.scss';

export default function Template({ data, location }) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;

  const pageUrl = pathBuilder(
    frontmatter.language,
    frontmatter.date,
    frontmatter.title
  );
  const imageUrl = assetPathBuilder(frontmatter.image);
  const parsedHtml = html.split('src="assets/').join('src="/assets/');

  const date = Date.parse(frontmatter.date);

  const dateTxt = dateBuilder(frontmatter.date);

  const shareOnFacebook = facebookPath(
    frontmatter.language,
    dateTxt,
    frontmatter.title
  );

  const shareOnTwitter = twitterPath(
    frontmatter.language,
    dateTxt,
    frontmatter.title,
    frontmatter.category
  );

  const disqusShortname = 'lj-blog';

  const disqusConfig = {
    url: `https://lajardinera.msfint.com${pageUrl}`,
    identifier: frontmatter.id,
    title: frontmatter.title,
    language: frontmatter.language,
  };

  return (
    <Layout location={location}>
      <ContentWrapper isPageWrapper>
        <Helmet>
          <title>{frontmatter.title}</title>
          <meta name="description" content={frontmatter.previewText} />
          <meta property="og:title" content={frontmatter.title} />
          <meta property="og:type" content="article" />
          <meta
            property="og:url"
            content={`https://lajardinera.msfint.com${pageUrl}`}
          />
          <meta
            property="og:image"
            content={`https://lajardinera.msfint.com${imageUrl}`}
          />
          <meta property="og:description" content={frontmatter.previewText} />
          <meta
            property="article:published_time"
            content={new Date(frontmatter.date).toISOString()}
          />
        </Helmet>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.shareIcons}>
              <a href={shareOnFacebook} className={styles.facebook}>
                <FacebookIcon />
              </a>
              <a href={shareOnTwitter} className={styles.twitter}>
                <TwitterIcon />
              </a>
            </div>
            <span className={styles.date}>
              <FormattedDate
                value={date}
                year="numeric"
                month="long"
                day="2-digit"
              />
            </span>
          </div>
          <h1 className={styles.title}>{frontmatter.title}</h1>
          <img src={imageUrl} alt="article preview" />
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: parsedHtml }}
          />
          <div className={styles.comments}>
            <FormattedMessage id="template.comments" />
            <DiscussionEmbed
              shortname={disqusShortname}
              config={disqusConfig}
            />
          </div>
        </div>
      </ContentWrapper>
    </Layout>
  );
}

Template.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query BlogPostByPath($mdPath: String!) {
    markdownRemark(frontmatter: { path: { eq: $mdPath } }) {
      html
      frontmatter {
        date
        path
        title
        image
        language
        previewText
      }
    }
  }
`;
