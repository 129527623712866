const facebookPath = (language, date, title) => {
  const pathTitle = title.split(' ').join('-').toLowerCase();

  const facebookBase = 'https://www.facebook.com/sharer.php?';
  const url = `u=https%3A%2F%2Flajardinera.msfint.com%2F${language}%2F${date}-${pathTitle}`;

  return `${facebookBase}${url}`;
};

export default facebookPath;
