const twitterPath = (language, date, title) => {
  const pathTitle = title.split(' ').join('-').toLowerCase();

  const twitterBase = 'https://twitter.com/intent/tweet?';
  const url = `url=https%3A%2F%2Flajardinera.msfint.com%2F${language}%2F${date}-${pathTitle}&`;
  const text = `text=${title}&hashtags=LaJardinera`;

  return `${twitterBase}${url}${text}`;
};

export default twitterPath;
