const illegalLetters = /[?!¿¡]/g;

const pathBuilder = (language, date, title) => {
  const convertedTitle = title.toLowerCase().split(' ').join('-');
  const path = `/${language}/${dateBuilder(date)}-${convertedTitle}`;
  return path.replace(illegalLetters, '');
};

const dateBuilder = (date) => {
  if (date.length > 8) {
    const convertedDate = date.split('-');
    const lastItem = convertedDate[2].substring(0, 2);
    convertedDate[2] = lastItem;

    return convertedDate.join('-');
  }
  return date;
};

const assetPathBuilder = (assetPath) => {
  if (assetPath[0] !== '/') {
    return `/${assetPath}`;
  }
  return assetPath;
};

module.exports = {
  assetPathBuilder,
  pathBuilder,
  dateBuilder,
};
